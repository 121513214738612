@import 'styles/master.scss';

$blue: #0c6ceb;
$blue-darker: #0e54b0;
$blue-lighter: #448ceb;
$white: #fff;
$grayed-out: #555;
$gray: #282c34;
$red: #e43b3b;
$yellow: #FCBE2C;


.App {
  text-align: center;
  background-color: $gray;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', '', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $gray;
}

.AppHeader {
  background-color: $gray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  h1 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', '', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    text-align: center;
    color: $white;
    font-size: 40px;
    padding-top: 50px;
  }
  
  h2 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', '', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    text-align: center;
  }
  
  h3 { 
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', '', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    text-align: center;
  }
  
  h4 {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', '', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    text-align: center;
  }

  .presents {
    font-size: 25px;
  }
  
  .name {
    font-family: $code-font;
  }

  .yellow {
    color: $yellow;
  }

  .settingsTitle {
    font-size: 25px;
    margin: 0;
  }

  /* form */
  .form {
    max-width: 50vw;
  }

  @media (max-width: 850px) {
    .form {
      max-width: 85vw;
    }
  }
  
  .formItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .formSlider {
    width: 300px !important;
  }

  .formLabel, .formSliderValue {
    font-size: 15px;
    margin: 0px 7px;
    min-width: 100px;
  }    
  

  .formMoreOptions {
    font-size: 15px;
  }

  .formMoreOptionsContainer {
    margin-top: 10px;
    flex-direction: column;
  }

  .formTextField {
    label {
      color: $white;
    }
    div {
      input {
        color: $white !important;
      }
      fieldset {
        border-color: $white !important;
      }
    }
  }

  .formTextArea {
    div {
    //  height: 200px;
      textarea {
     //   height: 160px !important;
        color: $white;
      }
    }
  }

  .formCheckbox {
    span {
      svg {
        fill: white !important;
      }
    }
  }

  .formSubmit {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  /********/
  
  .btn_primary {
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    background-color: $blue;
    border-color: $blue;
    height: 70px;
    width: auto;
    font-size: 35px;
    border-radius: 35px;
    letter-spacing: 7px;
    font-weight: 700;
    transition: 0.5s;
    border: none;
    cursor: pointer;
    padding: 0px 40px;
  }
  
  .btn_primary:hover {
    background-color: $blue-darker;
    border-color: $blue-darker;
    transform: scale(1.1,1.1);
  }
  
  .btn_primary:active {
    background-color: $blue-lighter;
    border-color: $blue-lighter;
    transform: scale(0.9, 0.9);
    outline: none;
  }
  
  .btn_primary:focus {
    border: none !important;
    outline: none;
  }
  
  label {
    color: $white !important;
  }
  
  .value {
    color: $white;
  }
    
  .inspired, .inspired2 {
    font-size: 15px;
  }

  .inspired2 {
    margin-bottom: 50px;
  }
}