@import url('https://fonts.googleapis.com/css2?family=PT+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$code-font: 'PT Mono', 'Courier New', monospace;
$default-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$blog-font: 'Source Sans Pro', $default-font;

$bg-color: #000;
$font-color: #fff;

$white: #fff;
$light-gray: #999;
$grayed-out: #555;
$gray: #282c34;
$dark-gray: #222;
$black: #000;
$red: #e43b3b;
$yellow: #FCBE2C;
$orange: #fa8220;
$green: #2ed175;
$blue: #1890ff;
$blue-darker: #0e54b0;
$blue-lighter: #448ceb;

@mixin mobile($max-width: 550px) {
  @media (max-width: $max-width) {
    @content;
  }
}

html {
  background-color: $bg-color;
}

body {
  margin: 0;
  font-family: $default-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $bg-color;
  color: $font-color;

  line-height: 1.5715;
}

h1, h2, h3, h4, h5, h6 {
  color: $font-color;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: $font-color;
}

a {
  color: $blue;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.25s;
  &:hover {
    color: adjust-color($blue, $lightness: 20%);
  }
}

.centeredContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centeredContainerHorz {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.padding {
  padding: 15px;
}

.MuiButton-root {
  text-transform: none !important;
}