.defaultHandle{
    position: absolute;
    box-sizing: border-box;
    background-color: white;
    border-radius: 100%;
    transform: translate(0,-50%);
}

.barBackground{
    height: 90%;
    width: 100%;
    mask-image: url('../assets/mask.svg');
    -webkit-mask-image: url('../assets/mask.svg');
    mask-size: contain;
    -webkit-mask-size: contain;
}
