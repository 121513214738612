@import "styles/master.scss";

$darkBlue: #100f24;
$lightBlue: #31489c;
$cyan: #a2ffee;
$purple: #8f5cef;
$violet: #a231cb;
$pink: #d993c1;

.loader {
  svg > circle {
    stroke: $violet;
  }
}

.wrapper {
  display: grid;
  align-items: flex-start;
  margin-top: 20px;
  div {
    grid-row: 1;
    grid-column: 1;
  }
  overflow: hidden;
}

.btn {
  display: flex;
  align-items: center;
  background-color: $violet;
  font-size: 25px;
  font-weight: 700;
  color: $white;
  padding: 10px 20px;
  border-radius: 100px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: transform 0.3s;
  svg {
    padding-right: 5px;
  }
  p {
    margin: 0;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

.btnBack {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: $purple;
  }
  width: 25px;
  height: 25px;
  font-size: 25px;
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
}

.header {
  display: grid;
  width: 75vw;
  justify-content: left;
  align-items: center;
  padding-bottom: 10px;
  h1, button {
    grid-row: 1;
    padding-left: 5px;
  }
  h1 {
    margin: 0;
  }
}

.form {
  width: 75vw;
}

.textField, .textArea {
  display: block !important;
  label {
    color: $white;
  }
  div {
    input {
      width: 75vw;
      color: $white !important;
    }
    fieldset {
      border-color: $white !important;
    }
  }
  svg {
    fill: $white;
  }
  margin-bottom: 20px !important;
}

.textArea {
  display: block !important;
  div {
    textarea {
      width: 75vw;
      color: $white;
    }
  }
}

.latestVersion {
  margin: 0;
  font-style: oblique;
  &:last-of-type {
    margin-bottom: 20px;
  }
}

.fileUploadContainer {
  div {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-right: 10px;
    }
  }
  p {
    margin: 0;
    margin-right: 10px;
  }
  .error {
    color: $red;
  }
  margin-bottom: 20px;
}

.fileUpload {
  background-color: $purple !important;
  color: $white !important;
  svg {
    fill: $white !important;
    transition: transform 0.5s, fill 0.5s;
    margin-right: 5px;
  }
  input {
    display: none;
  }
}

.modalRelease {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* TRANSITIONS */

.divOutLeft, .divOutRight, .divInLeft, .divInRight {
  display: block;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-fill-mode: forwards;
}

.divOutLeft {
  animation-name: divOutLeft-anim;
}

.divOutRight {
  animation-name: divOutRight-anim;
}

.divInLeft {
  animation-name: divInLeft-anim;
}

.divInRight {
  animation-name: divInRight-anim;
}

.divOffscreen {
  transform: translateX(100vw);
}

@keyframes divOutLeft-anim {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(-100vw);
  }
}

@keyframes divOutRight-anim {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(100vw);
  }
}

@keyframes divInRight-anim {
  from {
      transform: translateX(100vw);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes divInLeft-anim {
  from {
      transform: translateX(-100vw);
  }
  to {
      transform: translateX(0);
  }
}