.outerContainer{
  display: flex;
  flex-direction: column;
  background-color: white;
}

.pickedColorContainer{
  flex: 1;
}

.pickedColor{
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  height: calc(88% - 2px);
  margin: 2% 8.33% 0 8.33%;
  background-color: red;
}

.hexValue{
  font-family: serif;
  font-weight: bold;
  transition: color 0.5s;
}
