@import "styles/master.scss";

.pageH1 {
  text-align: center;
  font-weight: 500;
  font-size: 2em;
}

.pageH2 {
  margin: 20px;
  text-align: center;
  font-size: 35px;
  @include mobile(900px) {
    font-size: 25px;
  }
}

.pageH3 {
  text-align: center;
  font-style: italic;
}

.queueAvailable {
  color: $green;
  font-weight: bold;
}

.queueWait {
  color: $orange;
  font-weight: bold;
}

.button {
  font-family: $default-font;
  width: 100%;
  background: none;
  color: white;
  outline: none;
  border: 2px solid white;
  border-radius: 200px;
  font-size: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.25s, background-color 0.25s, color 0.25s, filter 0.25s;
  &:hover {
    transform: scale(101%);
    background-color: rgba(white, 0.2);
  }
  &:active {
    transform: scale(99%);
    background-color: white;
    color: black;
    img {
      filter: invert(1);
    }
  }
  img {
    width: 80px;
    height: 80px;
    padding: 10px 20px;
  }
  .buttonArrow {
    width: 50px;
    height: 50px;
    @include mobile {
      width: 35px;
      height: 35px;
    }
    @include mobile(400px) {
      width: 20px;
      height: 20px;
    }
  }
  .buttonQueueLoading {
    width: 15px !important;
    height: 15px !important;
    margin-right: 10px;
  }
  @include mobile {
    font-size: 18px;
    img {
      width: 60px;
      height: 60px;
      padding: 5px 10px;
    }
  }
}

.buttonMiddle {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: white;
  margin: 5px 0px;
}

.buttonTitle {
  font-size: 3vmax;
  @include mobile {
    font-size: 2.5vmax;
    margin-top: 5px;
  }
  @include mobile(400px) {
    font-size: 18px;
  }
}

.buttonStatus {
  margin-top: 10px;
  font-size: 1.5vmax;
  span {
    font-size: 1.5vmax;
    margin: 0;
    @include mobile(400px) {
      font-size: 10px;
    }
  }
  @include mobile(650px) {
    margin: 5px 0px;
  }
  @include mobile(400px) {
    font-size: 10px;
  }
}